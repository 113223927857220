define("discourse/plugins/discourse-ayah-font/discourse-markdown/ayah-markdown-extension", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;
  function setup(helper) {
    if (!helper.markdownIt) {
      return;
    }
    helper.whiteList(['span.ayah', 'div.ayah']);
    helper.registerPlugin(md => {
      md.inline.bbcode.ruler.push('ayah', {
        tag: 'q',
        wrap: 'span.ayah'
      });
    });
  }
});