define("discourse/plugins/discourse-ayah-font/initializers/ayah-markdown", ["exports", "discourse/lib/plugin-api", "I18n"], function (_exports, _pluginApi, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeAyahMarkdownButton(api) {
    let currentLocale = _I18n.default.currentLocale();
    if (!_I18n.default.translations[currentLocale].js.composer) {
      _I18n.default.translations[currentLocale].js.composer = {};
    }
    _I18n.default.translations[currentLocale].js.ayahFont_button = "خط ءاية";
    _I18n.default.translations[currentLocale].js.composer.ayahFont_prompt = ""; // leave empty

    api.onToolbarCreate(function (toolbar) {
      toolbar.addButton({
        trimLeading: true,
        id: "ayahFont-button",
        group: "insertions",
        icon: "book",
        // change icon
        title: "ayahFont_button",
        perform: function (e) {
          return e.applySurround('[q]', "[/q]", "ayahFont_prompt");
        }
      });
    });
  }
  var _default = _exports.default = {
    name: "ayah-mdbutton",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("0.8", initializeAyahMarkdownButton);
    }
  };
});